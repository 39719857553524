$(document).on('click', '.play', function() {
    var elem = $(this),
        video = elem.siblings('video').get(0),
        pause = elem.siblings('.pause');
    
    video.play();
    console.log(pause);
    $(this).removeClass('active')
    $(pause).addClass('active');
})

$(document).on('click', '.pause', function() {
    var elem = $(this),
        video = elem.siblings('.video').get(0),
        play = elem.siblings('.play');
    
    video.pause();
    $(this).removeClass('active');
    $(play).addClass('active');
})