$(document).ready(function () {
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        variableWidth: true,
        autoplaySpeed: 2000,
        speed: 500,
        centerMode: true,
        prevArrow: '<div class="icon-left-arrow prev theme"></div>',
        nextArrow: '<div class="icon-right-arrow next theme"></div>',
    })

    $('.advantages-slides').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<div class="icon-left-arrow prev theme"></div>',
        nextArrow: '<div class="icon-right-arrow next theme"></div>',
        responsive: [
        {
            breakpoint: 1023.98,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 767.98,
            settings: {
                slidesToShow: 1
            }
        }
        ]
    })

    $('.ads-cards').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<div class="icon-left-arrow prev theme"></div>',
        nextArrow: '<div class="icon-right-arrow next theme"></div>',
        responsive: [
            {
                breakpoint: 1023.98,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    })

    $('.reviews-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<div class="icon-left-arrow prev theme"></div>',
        nextArrow: '<div class="icon-right-arrow next theme"></div>',
    })
});